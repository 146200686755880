import { Box, Button, Grid,Divider } from "@mui/material";
import { useForm } from "react-hook-form";

import { CreateUpdateReductionMeasuresDesignProps } from "./data/CreateUpdateReductionMeasuresDesignProps";
import { ReductionMeasureModel } from "object-models/ReductionMeasures";
import MeasureDetails from "./MeasureDetails";
import MeasureReduction from "./MeasureReduction";
import MeasureFinancials from "./MeasureFinancials";
import MeasureMCA from "./MeasureMCA";
import MeasureTiming from "./MeasureTiming";

export default function CreateUpdateReductionMeasuresDesign(
  props: Readonly<CreateUpdateReductionMeasuresDesignProps>
) {
  const { handleSubmit } = useForm<ReductionMeasureModel>();

  return (
    <form
      onSubmit={handleSubmit(() => {
        props.onSave();
      })}
    >
      <Box sx={{ m: 5 }}>
        <MeasureDetails {...props} />
        <br />
        <Divider />
        <br />
        <MeasureReduction {...props} />
        <br />
        <Divider />
        <br />
        <MeasureTiming {...props} />
        <br />
        <Divider />
        <br />
        <MeasureMCA {...props} />
        <br />
        <Divider />
        <br />
        <MeasureFinancials {...props} />
        <br />
        <Divider />
        <br />
        <Grid container justifyContent="flex-end" style={{position:"absolute",bottom:"2rem",right:"2rem"}}>
          <Button type="submit" variant="contained" data-testid="btnSave">
            {props.isEdit ? "Save Measure Details" : "Add Measure Details"}
          </Button>
        </Grid>
      </Box>
    </form>
  );
}
