import { useMemo } from 'react';
import { OpexSummary } from './data/OpexSummary';
import { Grid, Typography } from '@mui/material';
import { DataTable } from 'components/DataTable';
import { GridColDef } from '@mui/x-data-grid';



export const OpexSummaryColumns: GridColDef[] = [
    {
      field: "year",
      headerClassName: "table-header",
      headerName: "Year",
      width: 250,
    },
    {
      field: "opex",
      headerClassName: "table-header",
      headerName: "Opex",
      width: 250,
      renderCell: (params) => (
        <Typography variant="body2" sx={{ fontSize: 14, fontWeight: "light" }}>
          {params.row.opex.toLocaleString("en-US")}
        </Typography>
      ),
    },
  ];

const OpexComponent: React.FC<{
  startYear: number;
  endYear: number;
  growthRate: number;
  opexValue: number;
}> = ({ startYear, endYear, growthRate, opexValue }) => {
  
  const opexSummary: OpexSummary[] = useMemo(() => {
    const data: OpexSummary[] = [];

    for (let i = 0; i <= endYear - startYear; i++) {
      const year = startYear + i;
      const baselineTotalEmissions = Number(opexValue);

      const opexValueForYear =
        i === 0
          ? baselineTotalEmissions
          : (
              baselineTotalEmissions *
              (1 + (growthRate / 100) * (year - startYear))
            ).toFixed(2);

      const opex: OpexSummary = {
        nzdmId: i + 1,
        year: year,
        opex: Number(opexValueForYear),
      };

      data.push(opex);
    }
    return data;
  }, [startYear, endYear, growthRate, opexValue]);

  return (
    <Grid container>
            <Grid item xs={11} md={11} lg={11}>
              <DataTable
                columns={OpexSummaryColumns}
                rows={opexSummary}
                hidePagination={false}
                disableCheckBox={true}
              />
            </Grid>
    </Grid>
  );
};

export default OpexComponent;
