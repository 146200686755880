import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";

import { GHGTarget } from "./data/GHGTarget";
import MultiSelect from "./MultiSelect";
import {
  _addTargetForm,
  _updateTargetForm,
} from "middlewares/TargetformApi/targetform";
import Spinner from "components/Spinner";
import RequiredLabel from "./RequiredLabel";
import { DataTable } from "components/DataTable";
import { Scope3GHGReductionModel } from "./data/Scope3GHGReduction";
import { TitleDefinitionConstants } from "constants/constant";
import Alerts from "components/Alerts";
import numericValidator from "services/numericValidator";
import {
  baseYears,
  scopesCoveredOptions,
  targetYears,
} from "./data/TargetFormData";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import Blurb from "components/Blurb/Blurb";

// Define the props interface
interface AddTargetFormProps {
  handleCloseSnackBar: (state: AlertsProps) => void;
  hideDialogHandler: () => void;
  getCustomGHGTargetsByOrganizationsId: () => void;
  defaultGHGTarget: GHGTarget;
  initialCategories: Scope3GHGReductionModel[];
}

/**
 * Component for adding or updating a GHG target form.
 * @param {AddTargetFormProps} props - The props for the component.
 * @returns {JSX.Element} AddTargetForm component.
 */
const AddTargetForm = (props: AddTargetFormProps) => {
  // Extract organization ID from the route parameters
  const { OrganizationId } = useParams();
  const parsedOrganizationId = Number(OrganizationId);

  const initialTargetForm: GHGTarget = {
    ...props.defaultGHGTarget,
    orgID: parsedOrganizationId,
  };

  // // State variables
  const [targetForm, setTargetForm] = useState<GHGTarget>(initialTargetForm);
  const [hasBaselineScope3GHG, setHasBaselineScope3GHG] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isScopesCovered, setIsScopesCovered] = useState(false);
  const [isScopes3Covered, setIsScopes3Covered] = useState(false);
  const [isYearValid, setIsYearValid] = useState(false);
  const [selectedScopes, setSelectedScopes] = useState<number[]>([]);
  const [categoryselectedIds, setCategoryselectedIds] = useState(
    [] as Array<number>
  );
  const [updatedCategories, setUpdatedCategories] = useState<
    Scope3GHGReductionModel[]
  >(props.initialCategories);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const CategoryColumns: GridColDef[] = [
    {
      field: "catagory",
      headerName: "Category",
      width: 300,
      disableColumnMenu: true,
    },
    {
      field: "catagoryName",
      headerName: "Category Name",
      width: 350,
      disableColumnMenu: true,
    },
    {
      field: "reduction",
      headerName: "% Reduction",
      width: 350,
      description: TitleDefinitionConstants.ScopeReduction,
      renderCell: (params) => (
        <FormControl fullWidth>
          <TextField
            value={params.row.reduction ?? 0}
            name={`reduction-${params.row.nzdmId}`}
            inputProps={{
              "data-testid": "reduction",
              inputMode: "numeric",
              maxLength: 5,
            }}
            size="small"
            variant="outlined"
            placeholder="% Reduction"
            onChange={(e) =>
              handleReductionChange(params.row.nzdmId, e.target.value)
            }
            disabled={!params.row.selected}
          />
        </FormControl>
      ),
      disableColumnMenu: true,
    },
  ];

  /**
   * Updates categories from the target form.
   */
  
  const updateCategoriesFromTargetForm = () => {
    const updatedCategoriesCopy = [...updatedCategories];
    const newCategoryselectedIds: number[] = [];

    updatedCategoriesCopy.forEach((category) => {
      const matchingScope = targetForm.scope3GHGReduction?.find(
        (scope) => scope.catagory === category.catagory
      );

      if (matchingScope) {
        // Update the values for matching categories
        category.reduction = matchingScope.reduction;
        category.selected = true;
        category.id = matchingScope.id;
        newCategoryselectedIds.push(category.catagory);
      } else {
        // No match found, set reduction to zero
        category.reduction = "0";
        category.selected = false;
      }
    });

    setUpdatedCategories(updatedCategoriesCopy);
    setCategoryselectedIds(newCategoryselectedIds);
    setHasBaselineScope3GHG(targetForm?.scopesCovered.includes("3"));
  };

  // Effect to update selected scopes and categories when the targetForm changes
  useEffect(() => {
    const selectedOptions = targetForm.scopesCovered
      ? targetForm.scopesCovered.split(",").map((scope) => parseInt(scope, 10))
      : [];

    setSelectedScopes(selectedOptions);

    const initialTargetForm: GHGTarget = {
      ...props.defaultGHGTarget,
      orgID: parsedOrganizationId,
    };

    setTargetForm(initialTargetForm);

    register("scope1Reduction");
    setValue("scope1Reduction", targetForm.scope1Reduction);

    register("scope2Reduction");
    setValue("scope2Reduction", targetForm.scope2Reduction);

    const initialCategoriesCopy = props.initialCategories.map((category) => ({
      ...category,
      reduction: "0",
      id: 0,
      selected: false,
    }));

    setUpdatedCategories(initialCategoriesCopy);

    setCategoryselectedIds([]);
    if (targetForm.targetNumber > 0 && targetForm.scopesCovered.includes("3")) {
      updateCategoriesFromTargetForm();
    }
  }, [props.defaultGHGTarget, props.initialCategories]);

  //change events

  const handleTextChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
      | SelectChangeEvent<number>
  ) => {
    setTargetForm({
      ...targetForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleScopeTextChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
      | SelectChangeEvent<number>
  ) => {
    setTargetForm({
      ...targetForm,
      [event.target.name]: numericValidator.allowNumericValue(
        event.target.value.toString()
      ),
    });
  };

  const handleSelectionChange = (selectedOptions: number[]) => {
    setHasBaselineScope3GHG(selectedOptions.includes(3));

    const selectedOptionsString = selectedOptions.join(", ");

    setTargetForm({
      ...targetForm,
      scopesCovered: selectedOptionsString,
      scope3GHGReduction:
        targetForm.targetNumber > 0 && hasBaselineScope3GHG
          ? null
          : targetForm.scope3GHGReduction,
    });
    setSelectedScopes(selectedOptions);
    setIsScopesCovered(false);
  };

  const handleReductionChange = (id: number, value: string) => {
    setUpdatedCategories((prevCategories) => {
      const updatedCategoriesCopy = prevCategories.map((category) => {
        if (category.catagory === id) {
          return {
            ...category,
            reduction: numericValidator.allowNumericValue(value),
          };
        }
        return category;
      });

      const selectedCategories = updatedCategoriesCopy.filter(
        (category) => category.selected
      );

      if (selectedCategories.length > 0) {
        setTargetForm((prevTargetForm) => ({
          ...prevTargetForm,
          scope3GHGReduction: selectedCategories,
        }));
      } else {
        setTargetForm((prevTargetForm) => ({
          ...prevTargetForm,
          scope3GHGReduction: null,
        }));
      }
      return updatedCategoriesCopy;
    });
  };

  const handleSelectionChangenew = (
    newSelectedRows: Scope3GHGReductionModel[]
  ) => {
    const updatedCategoriesCopy = updatedCategories.map((category) => {
      const isSelected = newSelectedRows.some(
        (row) => row.catagory === category.catagory
      );
      return {
        ...category,
        selected: isSelected,
      };
    });

    updatedCategoriesCopy.forEach((category) => {
      if (!newSelectedRows.some((row) => row.catagory === category.catagory)) {
        category.reduction = "0";
        category.selected = false;
      }
    });
    const selectedCategories = updatedCategoriesCopy.filter(
      (category) => category.selected
    );

    const newCategoryselectedIds = updatedCategoriesCopy
      .filter((category) => category.selected)
      .map((category) => category.catagory);

    setCategoryselectedIds(newCategoryselectedIds);
    setUpdatedCategories(updatedCategoriesCopy);
    setTargetForm((prevTargetForm) => ({
      ...prevTargetForm,
      scope3GHGReduction: selectedCategories,
    }));
  };

  const handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    setCheckboxChecked(isChecked);
    if (isChecked) {
      updateAllCategories(textFieldValue);
    } else {
      resetAllCategories();
    }
  };

  const handleTextFieldChange = (event: any) => {
    const value = event.target.value;
    setTextFieldValue(value);
    if (checkboxChecked) {
      updateAllCategories(value);
    }
  };

  const updateAllCategories = (value: any) => {
    const updatedCategoriesCopy = updatedCategories.map((category) => ({
      ...category,
      reduction: numericValidator.allowNumericValue(value),
      selected: true,
    }));
    const selectedCategories = updatedCategoriesCopy.filter(
      (category) => category.selected
    );
    setUpdatedCategories(updatedCategoriesCopy);
    setCategoryselectedIds(
      updatedCategoriesCopy.map((category) => category.catagory)
    );
    setTargetForm((prevTargetForm) => ({
      ...prevTargetForm,
      scope3GHGReduction: selectedCategories,
    }));
  };

  const resetAllCategories = () => {
    const updatedCategoriesCopy = updatedCategories.map((category) => ({
      ...category,
      reduction: "0",
      selected: false,
    }));
    setUpdatedCategories(updatedCategoriesCopy);
    setCategoryselectedIds([]);
    setTextFieldValue("");
    setTargetForm((prevTargetForm) => ({
      ...prevTargetForm,
      scope3GHGReduction: [],
    }));
  };

  // Save the form data

  const handleSaveOrUpdate = (apiFunction: Function) => {
    if (typeof apiFunction === "function") {
      setLoading(true);
      apiFunction(targetForm)
        .then((res: GHGTarget) => {
          if (res) {
            setLoading(false);
            const message =
              targetForm.targetNumber === 0
                ? `${targetForm.targetName} Created Successfully !`
                : "Data Saved Successfully !";
            props.getCustomGHGTargetsByOrganizationsId();
            props.handleCloseSnackBar({
              show: true,
              severity: "success",
              message: message,
            });
            props.hideDialogHandler();
          }
        })
        .catch((err: any) => {
          setLoading(false);
        });
    } else {
      console.error("apiFunction is not a function");
    }
  };

  const onSave = () => {
    let isValid = true;
    if (targetForm.targetYear <= targetForm.baseYear) {
      isValid = false;
      setIsYearValid(true);
    } else {
      setIsYearValid(false);
    }
    if (targetForm.scopesCovered?.length === 0) {
      setIsScopesCovered(true);
      isValid = false;
    } else {
      setIsScopesCovered(false);
    }

    if (
      targetForm.scopesCovered?.includes("3") &&
      targetForm.scope3GHGReduction === null
    ) {
      setIsScopes3Covered(true);
      isValid = false;
    } else {
      setIsScopes3Covered(false);
    }

    if (isValid) {
      if (targetForm.targetNumber > 0) {
        handleSaveOrUpdate(_updateTargetForm);
      } else {
        handleSaveOrUpdate(_addTargetForm);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSave)} data-testid="form">
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <Typography sx={{ fontWeight: 600, pb: 1.5, color: "red" }}>
                * Indicates required fields
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              {isScopesCovered && (
                <Alerts
                  severity="error"
                  message="At least one must be selected from GHG Scopes Covered"
                  show={true}
                />
              )}
              {isScopes3Covered && (
                <Alerts
                  severity="error"
                  message="At least one must be selected from categories"
                  show={true}
                />
              )}
              {isYearValid && (
                <Alerts
                  severity="error"
                  message="The target year value cannot be less than Base year value"
                  show={true}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <RequiredLabel
                labelText="Target Name"
                isHelperTextRequired={false}
                content=""
              />
              <TextField
                {...register("targetName", {
                  required: true,
                })}
                inputProps={{ "data-testid": "targetName" }}
                required
                name="targetName"
                onChange={(e) => {
                  clearErrors("targetName");
                  handleTextChange(e);
                }}
                value={targetForm.targetName}
                size="small"
                fullWidth
                error={!!errors.targetName}
              />
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <RequiredLabel
                labelText="Base Year"
                isHelperTextRequired={true}
                content={TitleDefinitionConstants.BaseYear}
              />
              <FormControl fullWidth>
                <Select
                  {...register("baseYear", {
                    required: true,
                  })}
                  data-testid="baseYear"
                  required
                  value={targetForm.baseYear}
                  onChange={(e) => {
                    handleTextChange(e);
                  }}
                  displayEmpty
                  size="small"
                  fullWidth
                  error={!!errors.baseYear}
                >
                  <MenuItem value="">Select Base Year</MenuItem>
                  {baseYears.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={6}>
              <RequiredLabel
                labelText="Target Year"
                isHelperTextRequired={true}
                content={TitleDefinitionConstants.TargetYear}
              />
              <FormControl fullWidth>
                <Select
                  value={targetForm.targetYear}
                  required
                  data-testid="targetYear"
                  onChange={(e) => {
                    handleTextChange(e);
                  }}
                  displayEmpty
                  size="small"
                  fullWidth
                  name="targetYear"
                >
                  <MenuItem value="">Select Target Year</MenuItem>
                  {targetYears.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <RequiredLabel
                labelText="GHG Scopes Covered"
                isHelperTextRequired={true}
                content={TitleDefinitionConstants.ScopesCovered}
              />
              <MultiSelect
                options={scopesCoveredOptions}
                onSelectionChange={handleSelectionChange}
                selectedOptions={selectedScopes}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={6}>
              <RequiredLabel
                labelText="Scope 1 % Reduction"
                isHelperTextRequired={true}
                content={TitleDefinitionConstants.ScopeReduction}
              />
              <TextField
                {...register("scope1Reduction", {
                  validate: (value) => {
                    if (!selectedScopes.includes(1)) {
                      return true;
                    }
                    const floatValue = parseFloat(value);
                    return floatValue >= 0 && floatValue <= 100;
                  },
                })}
                name="scope1Reduction"
                required
                onChange={(e) => {
                  clearErrors("scope1Reduction");
                  handleScopeTextChange(e);
                }}
                value={
                  selectedScopes.includes(1) ? targetForm.scope1Reduction : "0"
                }
                size="small"
                fullWidth
                error={!!errors.scope1Reduction}
                inputProps={{
                  inputMode: "numeric",
                  maxLength: 5,
                  "data-testid": "scope1Reduction",
                }}
                disabled={!selectedScopes.includes(1)}
              />
            </Grid>
            <Grid item xs={6}>
              <RequiredLabel
                labelText="Scope 2 % Reduction"
                isHelperTextRequired={true}
                content={TitleDefinitionConstants.ScopeReduction}
              />
              <TextField
                {...register("scope2Reduction", {
                  validate: (value) => {
                    if (!selectedScopes.includes(2)) {
                      return true;
                    }
                    const floatValue = parseFloat(value);
                    return floatValue >= 0 && floatValue <= 100;
                  },
                })}
                name="scope2Reduction"
                onChange={(e) => {
                  clearErrors("scope2Reduction");
                  handleScopeTextChange(e);
                }}
                value={
                  selectedScopes.includes(2) ? targetForm.scope2Reduction : "0"
                }
                size="small"
                fullWidth
                error={!!errors.scope2Reduction}
                required
                inputProps={{
                  inputMode: "numeric",
                  maxLength: 5,
                  "data-testid": "scope2Reduction",
                }}
                disabled={!selectedScopes.includes(2)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 3, ml: 0.2 }}>
            <Blurb
              infoText={TitleDefinitionConstants.ScopeReductionNote}
              title={"Note:"}
            ></Blurb>
          </Grid>

          {hasBaselineScope3GHG && (
            <>
              <Divider />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    data-testid="BaselineScope3GHGEmissions"
                    sx={{ fontWeight: "bold", mb: 1.5, color: "#718096" }}
                  >
                    Baseline Scope 3 GHG Emissions
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxChecked}
                        onChange={handleCheckboxChange}
                        data-testid="allCategory"
                      />
                    }
                    label="Apply to all categories"
                  />
                  <TextField
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    size="small"
                    disabled={!checkboxChecked}
                    placeholder="Enter Reduction Percentage:"
                    inputProps={{
                      "data-testid": `reductionPercentage`,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <DataTable
                    columns={CategoryColumns}
                    rows={updatedCategories}
                    hidePagination={true}
                    onSelectionChange={handleSelectionChangenew}
                    selectionModel={categoryselectedIds}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Divider />
          <br />
          <Grid container justifyContent="flex-end" style={{position:"absolute",bottom:"2rem",right:"2rem"}}>
            <Button type="submit" variant="contained" data-testid="btnSave">
              Save Target
            </Button>
          </Grid>
        </Box>
      </form>
      {loading && <Spinner size={80} data-testid="spinner" />}
    </>
  );
};

export default AddTargetForm;
