import BaselineTargetEmission from "pages/Dashboards/BaselineTargetEmission/BaselineTargetEmission.Board";
import ProjectRegister from "pages/Dashboards/ProjectRegister/ProjectRegister";
import ScenarioView from "pages/Dashboards/ScenarioView/ScenarioView";
import UserList from "pages/ManageUsers/UserList";
import OrganizationDetails from "pages/OrganizationDetails";
import BaselineEmission from "pages/PortfolioView/BaselineEmission/BaselineEmission";
import CapexPlan from "pages/PortfolioView/CapexPlan/CapexPlan";
import PortfolioProjectRegister from "pages/PortfolioView/ProjectRegister/ProjectRegister";
import ReductionMeasures from "pages/ReductionMeasures/ReductionMeasures";
import DiscountFactor from "pages/Settings/DiscountFactor/DiscountFactor";
import Prices from "pages/Settings/Prices/Prices";
import UserRoleConfiguration from "pages/Settings/UserRole/UserRole";
import TargetForm from "pages/TargetForm";
import { Route, Routes } from "react-router-dom";
const PageRoutes = () => {
  const pageRoutesData = [
    {
      id: 1,
      path: "/Organization-Details",
      component: <OrganizationDetails />,
    },
    {
      id: 2,
      path: "/Target-Form",
      component: <TargetForm />,
    },
    {
      id: 3,
      path: "/Reduction-Measures",
      component: <ReductionMeasures />,
    },
    {
      id: 4,
      path: "/User-Management",
      component: <UserList />,
    },
    {
      id: 5,
      path: "/baselinetargetemissions",
      component: <BaselineTargetEmission />,
    },
    {
      id: 6,
      path: "/projectregister",
      component: <ProjectRegister />,
    },
    {
      id: 7,
      path: "/scenarioView",
      component: <ScenarioView />,
    },
    {
      id:8,
      path: "/prices",
      component:<Prices/>
    },
    {
      id: 9,
      path: "/baselineemissions",
      component: <BaselineEmission />,
    },
    {
      id: 10,
      path: "/portFolioProjectRegister",
      component: <PortfolioProjectRegister/>,
    },
    {
      id: 11,
      path: "/capexplan",
      component: <CapexPlan />,
    },
    {
      id: 12,
      path: "/userrole",
      component: <UserRoleConfiguration />,
    },
    {
      id: 13,
      path: "/discountfactor",
      component: <DiscountFactor/>,
    },
  ];

  return (
    <Routes>
      {pageRoutesData.map((item: any) => {
        return (
          <Route key={item.id} path={item.path} element={item.component} />
        );
      })}
    </Routes>
  );
};

export default PageRoutes;
