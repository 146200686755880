import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import FormDialog from "components/Dialog/modal-popup";
import CategoryInfoDetails from "./CategoryInfoDetails";
import DisplayItem from "./DisplayItem";
import Spinner from "components/Spinner";
import { OrganizationModel } from "object-models/organization-model";
import { _getOrganization } from "middlewares/OrganizationApi/organization";
import CreateUpdateOrganization from "pages/CreateUpdateOrganization";
import {
  Currency,
  TitleDefinitionConstants,
  UserRoles,
} from "constants/constant";
import AddGrowthRate from "./AddGrowthRate";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import SnackBar from "components/SnackBar";
import { useAppState } from "context/appState.context";
import OrganizationTitle from "pages/OrganizationTitle/OrganizationTitle";

/**
 * Component to display the details of an organization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const OrganizationDetails = () => {
  const { userRole,setOrgName,setDiscountFactor,setAverageAnnualGrowthRate } = useAppState();
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organizationDetails, setOrganizationDetails] =
    useState<OrganizationModel | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isGrowthRate, setIsGrowthRate] = useState(false);
  const { OrganizationId } = useParams();
  const [currency, setCurrency] = useState("");
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });

  /**
   * Handles the closing of the edit dialog and fetches updated organization details.
   */
  function hideEditDialogHandler() {
    hideDialogHandler();
    fetchOrganizationDetails();
  }

  /**
   * Closes the dialog.
   */
  function hideDialogHandler() {
    setDialogOpen(false);
  }

  /**
   * Handles closing the snackbar by updating its state.
   *
   * @param {AlertsProps} state - The state to set for the snackbar.
   */
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };

  /**
   * Calculates the total baseline emissions.
   *
   * @returns {number | string} The total baseline emissions or "0" if no details are available.
   */
  const calculateTotalBaselineEmissions = (): number | string => {
    if (organizationDetails) {
      const { scope1GHGEmission, scope2GHGEmission, scope3GHGEmission } =
        organizationDetails;
      const totalBaselineEmissions = (
        (Number(scope1GHGEmission) || 0) +
        (Number(scope2GHGEmission) || 0) +
        (Number(scope3GHGEmission) || 0)
      ).toFixed(2);

      return totalBaselineEmissions;
    }
    return 0;
  };

  /**
   * Fetches organization details from the API.
   */
  const fetchOrganizationDetails = async () => {
    try {
      setLoading(true);
      const res = await _getOrganization(Number(OrganizationId));
      if (res) {
        setOrganizationDetails(res);
        setOrgName(res?.orgName ? res?.orgName : '');
        setDiscountFactor(res?.discountFactor ? res?.discountFactor : 0);
        setAverageAnnualGrowthRate(res?.averageAnnualGrowthRate ? res?.averageAnnualGrowthRate : 0);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganizationDetails();
  }, [OrganizationId]);

  useEffect(() => {
    setCurrency(
      Currency.currency.find((c) => c.value === organizationDetails?.currency)
        ?.symbol ?? ""
    );
  }, [organizationDetails?.currency]);

  /**
   * Renders the organization details or a loading spinner if data is still being fetched.
   *
   * @returns {JSX.Element} The rendered organization details or a loading spinner.
   */
  const renderOrganizationDetails = () => {
    if (loading) {
      return <Spinner size={80} data-testid="spinner" />;
    } else if (organizationDetails) {
      return (
        <Grid container spacing={2} sx={{ mt: 0 }}>
          
          <Grid item xs={4} md={6.5}>
            <Typography
              fontSize="24px"
              fontFamily={"Inter"}
              fontWeight={700}
              data-testid="OrganizationDetails"
            >
              Organization Details
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign={"right"}>
            {userRole !== UserRoles.Viewers && (
              <Button
                variant="contained"
                data-testid="EditOrganizationDetailsButton"
                onClick={() => {
                  setIsEdit(true);
                  setDialogTitle("Edit Organization Details");
                  setDialogOpen(true);
                }}
              >
                Edit Organization Details
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
                  <OrganizationTitle />
                  <Grid item xs={10}>
                    <Divider data-testid="BackgroundInformationDivider" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 20,
                        fontWeight: "fontWeightBold",
                        color: "#718096",
                      }}
                      data-testid="BackgroundInformation"
                    >
                      Background Information
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="Industry"
                      helptext={
                        TitleDefinitionConstants.OrganizationDetails.Industry
                      }
                      isHelperTextRequired={true}
                      value={organizationDetails.industry}
                      dataTestIdlabel="IndustryLabel"
                      dataTestIdvalue="IndustryValue"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="Annual Revenue"
                      helptext={
                        TitleDefinitionConstants.OrganizationDetails
                          .AnnualRevenue
                      }
                      isHelperTextRequired={true}
                      value={currency + " " + organizationDetails.annualRevenue}
                      dataTestIdlabel="AnnualRevenueLabel"
                      dataTestIdvalue="AnnualRevenueValue"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="Number of Employees"
                      helptext={
                        TitleDefinitionConstants.OrganizationDetails
                          .NumberOfEmployees
                      }
                      isHelperTextRequired={true}
                      value={organizationDetails.noOfEmployees}
                      dataTestIdlabel="NoOfEmployeesLabel"
                      dataTestIdvalue="NoOfEmployeesValue"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <Divider data-testid="BackgroundInformationDivider" />
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 20,
                        fontWeight: "fontWeightBold",
                        color: "#718096",
                      }}
                      data-testid="BaselineGHGEmissions"
                    >
                      Baseline GHG Emissions
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="Scope 1 (mtCO₂e)"
                      helptext={
                        TitleDefinitionConstants.OrganizationDetails
                          .BaselineScope1
                      }
                      isHelperTextRequired={true}
                      value={organizationDetails.scope1GHGEmission?.toLocaleString(
                        "en-US"
                      )}
                      dataTestIdlabel="Scope1Label"
                      dataTestIdvalue="Scope1Value"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="Scope 2 (mtCO₂e)"
                      helptext={
                        TitleDefinitionConstants.OrganizationDetails
                          .BaselineScope2
                      }
                      isHelperTextRequired={true}
                      value={organizationDetails.scope2GHGEmission?.toLocaleString(
                        "en-US"
                      )}
                      dataTestIdlabel="Scope2Label"
                      dataTestIdvalue="Scope2Value"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="Scope 3 (mtCO₂e)"
                      helptext={
                        TitleDefinitionConstants.OrganizationDetails
                          .BaselineScope3
                      }
                      isHelperTextRequired={true}
                      value={organizationDetails.scope3GHGEmission?.toLocaleString(
                        "en-US"
                      )}
                      dataTestIdlabel="Scope3Label"
                      dataTestIdvalue="Scope3Value"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={10} textAlign={"right"}>
                  <Button
                    data-testid="ViewScope3ByCategoryButton"
                    variant="outlined"
                    onClick={() => {
                      setDialogOpen(true);
                      setDialogTitle("Baseline Scope 3 Emissions by Category");
                      setIsGrowthRate(false);
                      setIsEdit(false);
                    }}
                  >
                    View Scope 3 by Category
                  </Button>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="Total Baseline Emissions (mtCO₂e)"
                      helptext={
                        TitleDefinitionConstants.OrganizationDetails
                          .BaselineScope1
                      }
                      isHelperTextRequired={false}
                      value={Number(
                        calculateTotalBaselineEmissions()
                      ).toLocaleString("en-US")}
                      dataTestIdlabel="TotalBaselineEmissionsLabel"
                      dataTestIdvalue="TotalBaselineEmissionsValue"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="Average Annual Growth Rate"
                      helptext={
                        TitleDefinitionConstants.OrganizationDetails
                          .BaselineScope3
                      }
                      isHelperTextRequired={false}
                      value={organizationDetails?.averageAnnualGrowthRate ?? 0}
                      dataTestIdlabel="AverageAnnualGrowthRateLabel"
                      dataTestIdvalue="AverageAnnualGrowthRateValue"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={5.5} textAlign={"right"}>
                  {userRole !== UserRoles.Viewers && (
                    <Button
                      data-testid="averageAnnualGrowthRate"
                      variant="outlined"
                      onClick={() => {
                        setDialogOpen(true);
                        setDialogTitle("Add Growth Rate");
                        setIsGrowthRate(true);
                        setIsEdit(false);
                      }}
                    >
                      {organizationDetails.averageAnnualGrowthRate != null
                        ? "Update Growth Rate"
                        : "Add Growth Rate"}
                    </Button>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      );
    } else {
      return <div>No data available.</div>;
    }
  };

  let childComponent = null;
  if (isEdit) {
    childComponent = (
      <CreateUpdateOrganization
        isEdit={true}
        closeDialog={hideEditDialogHandler}
      />
    );
  } else if (isGrowthRate) {
    childComponent = (
      <AddGrowthRate
        baselineTotalEmissions={calculateTotalBaselineEmissions()}
        orgDetails={organizationDetails}
        closeDialog={hideDialogHandler}
        fetchOrganizationDetails={fetchOrganizationDetails}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    );
  } else {
    childComponent = <CategoryInfoDetails orgDetails={organizationDetails} />;
  }

  return (
    <React.Fragment>
      <FormDialog
        dialogOpen={dialogOpen}
        hideDialogHandler={hideDialogHandler}
        dialogTitle={dialogTitle}
        isFullScreenAllowed={true}
        childComponent={childComponent}
      ></FormDialog>

      {renderOrganizationDetails()}
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          handleCloseSnackBar({ show: false, severity: "info", message: "" })
        }
      />
    </React.Fragment>
  );
};

export default OrganizationDetails;
