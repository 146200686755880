import {
  Button,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import OrganizationTitle from "pages/OrganizationTitle/OrganizationTitle";
import { ChangeEvent, useState } from "react";

import { useParams } from "react-router-dom";
import Spinner from "components/Spinner/Spinner";
import numericValidator from "services/numericValidator";
import SnackBar from "components/SnackBar";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import { DiscountFactorModel } from "./data/DiscountFactorModel";
import { _updatediscountfactor } from "middlewares/OrganizationApi/organization";
import { useAppState } from "context/appState.context";

/**
 * Component for managing user role configuration for an organization.
 * @component
 */
const DiscountFactor = () => {
  const { OrganizationId } = useParams();
  const { discountFactor,setDiscountFactor } = useAppState();
  const [discountFactorModel, setDiscountFactorModel] = useState<DiscountFactorModel>({
      orgID:Number(OrganizationId),
      discountFactor: discountFactor,
    });
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setDiscountFactorModel((prevState) => ({
      ...prevState,
      [name]: numericValidator.allowNumericValue(value),
    }));
};

 
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const res = await _updatediscountfactor(discountFactorModel);
      if (res) {
        setLoading(false);
        setDiscountFactor(discountFactorModel.discountFactor?discountFactorModel.discountFactor:0);
        const message = "Data Saved Successfully !";
        handleCloseSnackBar({
          show: true,
          severity: "success",
          message: message,
        });
      }
     
    } catch (err) {
      console.error("Error:", err);
      handleCloseSnackBar({
        show: true,
        severity: "error",
        message: "Failed to save data. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <OrganizationTitle />

      <Card sx={{ mb: 3, p: 2 }}>
        <CardContent>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2 }}
            data-testid="userRoleConfigTitle"
          >
            Discount Factor Configuration
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={3}>
              <Typography
                variant="body2"
                sx={{ fontSize: 14, fontWeight: "light", mb: 1 }}
                data-testid="adminsLabel"
              >
                Discount Factor
              </Typography>
              <TextField
                required
                name="discountFactor"
                size="small"
                fullWidth
                inputProps={{
                  "data-testid": "discountFactor",
                  inputMode: "numeric",
                }}
                value={discountFactorModel.discountFactor}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button type="submit" variant="contained" data-testid="btnSave" onClick={handleSave}>
          Save
        </Button>
      </Box>

      {loading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <Spinner size={80} data-testid="spinner" />
        </Grid>
      )}
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          handleCloseSnackBar({ show: false, severity: "info", message: "" })
        }
      />
    </>
  );
};

export default DiscountFactor;
